import React, { useEffect, useRef } from "react";
import {
  flattenBlogData,
  removeBlogsForId,
} from "../../utils/index";
import { graphql } from "gatsby";
import parse from "html-react-parser";

import Layout from "../../components/Layout/Layout";
import Seo from "../../components/seo";
import WidgetContainer from "../../components/WidgetContainer/WidgetContainer";

import "./index.scss";
import HeroSection from "../../components/BlogDetailPage/HeroSection";
import SocialShare from "../../components/BlogDetailPage/SocialShare";
import SubHeader from "../../components/BlogDetailPage/SubHeader";
import BlogCard from "../../components/BlogCard";

const BlogPost = ({ data, location, pageContext }) => {
  const {
    post,
    defaultImage,
    moreBlogs,
    moreCategoryBlogs,
  } = data;
  const { categories } = pageContext;

  const blogData = flattenBlogData([post])[0];
  const host = process.env.GATSBY_SITE_URL;

  const progressBarRef = useRef(null);
  const blogContentEndRef = useRef(null);

  const moreBlogsData = removeBlogsForId(
    flattenBlogData([
      ...(moreCategoryBlogs.nodes ?? []),
      ...(moreBlogs.nodes ?? []),
    ]),
    blogData.id
  );

  useEffect(() => {
    let progresBar = progressBarRef.current;
    let blogContentEnd = blogContentEndRef.current;

    const windowHeight =
      document.documentElement.clientHeight;
    const documentHeight =
      blogContentEnd.offsetTop - windowHeight;

    const pageScroll = () => {
      window.requestAnimationFrame(() => {
        const percentScrolled = Math.min(
          (window.pageYOffset / documentHeight) * 100,
          100
        );

        progresBar.style.width = percentScrolled + "%";
      });
    };

    pageScroll();

    window.addEventListener("scroll", pageScroll, false);
    return () => {
      window.removeEventListener("scroll", pageScroll);
    };
  }, []);

  return (
    <Layout isHomePage>
      <Seo
        title={blogData.title}
        image={
          blogData.featuredImage?.node?.localFile
            ?.childImageSharp?.fluid?.src
        }
        pageURI={blogData.uri}
      />
      <SubHeader
        activeCategoryName={blogData.category}
        categoryMap={categories}
        location={location}
      />
      <div className="progress-bar-container">
        <div
          className="progress-bar"
          ref={progressBarRef}
        ></div>
      </div>
      <WidgetContainer stylesClass="blogs-detail-page-widget-container">
        <HeroSection
          defaultImage={defaultImage}
          blogData={{
            ...blogData,
          }}
        />
        <div className="blogs-detail-content">
          <SocialShare blogHref={`${host}${post.uri}`} />
          {parse(post.content || "")}
          <div
            ref={blogContentEndRef}
            style={{ position: "fixed" }}
          ></div>
        </div>

        <div className="keep-reading-section">
          <h2>Keep Reading</h2>
          <div className="keep-reading-section__grid">
            {moreBlogsData.slice(0, 3).map(blogPost => (
              <BlogCard
                key={blogPost.uri}
                postData={blogPost}
                defaultImage={defaultImage}
              />
            ))}
          </div>
        </div>
      </WidgetContainer>
    </Layout>
  );
};

export default BlogPost;

export const pageQuery = graphql`
  query BlogPostData(
    $id: String!
    $previousPostId: String
    $nextPostId: String
    $categoryId: String
  ) {
    defaultImage: file(
      relativePath: { eq: "blogs-default-image.jpg" }
    ) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    post: wpPost(id: { eq: $id }) {
      id
      title
      content
      date(formatString: "DD MMMM, YYYY")
      uri
      categories {
        nodes {
          name
          slug
        }
      }
      author {
        node {
          name
          slug
          description
        }
      }
      featuredImage {
        node {
          altText
          localFile {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
      subtitleAcf {
        text
      }
    }

    moreCategoryBlogs: allWpPost(
      sort: { order: DESC, fields: date }
      limit: 5
      filter: {
        categories: {
          nodes: { elemMatch: { id: { eq: $categoryId } } }
        }
      }
    ) {
      nodes {
        id
        excerpt
        uri
        date(formatString: "MMMM DD, YYYY")
        title
        excerpt
        categories {
          nodes {
            slug
            id
            name
          }
        }
        author {
          node {
            id
            name
          }
        }
        featuredImage {
          node {
            altText
            localFile {
              childImageSharp {
                fluid(quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
        subtitleAcf {
          text
        }
      }
    }

    moreBlogs: allWpPost(
      sort: { order: DESC, fields: date }
      limit: 5
    ) {
      nodes {
        id
        excerpt
        uri
        date(formatString: "MMMM DD, YYYY")
        title
        excerpt
        categories {
          nodes {
            slug
            id
            name
          }
        }
        author {
          node {
            id
            name
          }
        }
        featuredImage {
          node {
            altText
            localFile {
              childImageSharp {
                fluid(quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
        subtitleAcf {
          text
        }
      }
    }

    # this gets us the previous post by id (if it exists)
    previous: wpPost(id: { eq: $previousPostId }) {
      uri
      title
    }

    # this gets us the next post by id (if it exists)
    next: wpPost(id: { eq: $nextPostId }) {
      uri
      title
    }
    categoriesData: allWpCategory {
      nodes {
        id
        name
        count
        uri
      }
    }
    recentPosts: allWpPost(
      sort: { order: DESC, fields: date }
      limit: 5
    ) {
      nodes {
        id
        title
        uri
      }
    }
  }
`;
