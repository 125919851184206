import React from "react";
import PropTypes from "prop-types";
import "./index.scss";
import Image from "gatsby-image";

const BlogsDetailsHero = ({ defaultImage, blogData }) => {
  const featuredImage = {
    fluid:
      blogData.featuredImage?.node?.localFile
        ?.childImageSharp?.fluid,
    alt: blogData.featuredImage?.node?.alt || ``,
  };

  return (
    <div className="blogs-details-hero">
      <div className="image-container">
        {featuredImage?.fluid ? (
          <Image
            fluid={featuredImage.fluid}
            alt={featuredImage.alt}
          />
        ) : (
          <Image
            fluid={defaultImage?.childImageSharp?.fluid}
            objectFit="cover"
          />
        )}
      </div>
      <div className="content-container">
        <h2>{blogData?.category || "<Category>"}</h2>
        <h1>{blogData?.title || "<Title>"}</h1>
        <p className="blog-author-info">
          <span>
            {blogData?.subtitleAcf?.text || "<Author>"}
          </span>
          <span>{blogData?.date || ""}</span>
        </p>
      </div>
    </div>
  );
};

BlogsDetailsHero.propTypes = {
  blogData: PropTypes.object.isRequired,
  defaultImage: PropTypes.object.isRequired,
  currentLanguage: PropTypes.object,
  allAssociatedLanguages: PropTypes.array,
  currentBlogSlug: PropTypes.string,
  currentBlogCategorySlug: PropTypes.string,
};

export default BlogsDetailsHero;
