import React, { useRef } from "react";
import PropTypes from "prop-types";
import LinkedIn from "../../../images/social-logos/LinkedIn.svg";
import Twitter from "../../../images/social-logos/Twitter.svg";
import Facebook from "../../../images/social-logos/Facebook.svg";
import Copy from "../../../images/social-logos/Copy.svg";
import "./index.scss";
import { sendClickEvent } from "../../../helpers/garfield";
import {
  TECH_BLOG_POST_FB_SHARE,
  TECH_BLOG_POST_LI_SHARE,
  TECH_BLOG_POST_URL_COPY,
  TECH_BLOG_POST_X_SHARE,
} from "../../../data/techBlogEventConstants";

const BlogDetailPageSocialShare = ({ blogHref }) => {
  const toastRef = useRef(null);
  return (
    <>
      <div className="toast-message" ref={toastRef}>
        URL copied to clipboard
      </div>
      <div className="blogs-detail-social-media-container">
        <a
          href={`https://twitter.com/intent/tweet?url=${blogHref}`}
          target="_blank"
          rel="noopener noreferrer"
          onClick={() =>
            sendClickEvent(TECH_BLOG_POST_X_SHARE, blogHref)
          }
        >
          <img
            src={Twitter}
            alt="PhonePe | Blog Details | Twitter Share"
          />
        </a>
        <a
          href={`https://www.facebook.com/sharer/sharer.php?u=${blogHref}`}
          target="_blank"
          rel="noopener noreferrer"
          onClick={() =>
            sendClickEvent(
              TECH_BLOG_POST_FB_SHARE,
              blogHref
            )
          }
        >
          <img
            src={Facebook}
            alt="PhonePe | Blog Details | Facebook Share"
          />
        </a>
        <a
          href={`https://www.linkedin.com/shareArticle?url=${blogHref}`}
          target="_blank"
          rel="noopener noreferrer"
          onClick={() =>
            sendClickEvent(
              TECH_BLOG_POST_LI_SHARE,
              blogHref
            )
          }
        >
          <img
            src={LinkedIn}
            alt="PhonePe | Blog Details | LinkedIn Share"
          />
        </a>

        <img
          src={Copy}
          onClick={() => {
            sendClickEvent(
              TECH_BLOG_POST_URL_COPY,
              blogHref
            );
            window.navigator.clipboard
              .writeText(blogHref)
              .then(() => {
                toastRef.current.style.display =
                  "inline-block";

                setTimeout(() => {
                  toastRef.current.style.display = "none";
                }, 3000);
              });
          }}
          alt="PhonePe | Blog Details | Copy"
        />
      </div>
    </>
  );
};

BlogDetailPageSocialShare.propTypes = {
  blogHref: PropTypes.string,
};

export default BlogDetailPageSocialShare;
