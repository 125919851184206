import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import WidgetContainer from "../../WidgetContainer/WidgetContainer";
import Arrow from "../../../images/blogs/blogs-left-arrow.svg";
import { getCategoryFromUrlPathName } from "../../../utils";
import { Link } from "gatsby";
import "./index.scss";
import { TECH_BLOG_CATEGORY_DROP_DOWN } from "../../../data/techBlogEventConstants";
import { sendClickEvent } from "../../../helpers/garfield";

const BlogDetailPageSubHeader = ({
  activeCategoryName,
  categoryMap,
  location,
}) => {
  const dropdownRef = useRef(null);

  const [
    subHeaderDropdown,
    setSubHeaderDropdown,
  ] = useState(false);

  const [isFirst, setIsFirst] = useState(true);

  const handleSubheaderDropdown = useCallback(() => {
    setSubHeaderDropdown(value => !value);
    if (isFirst) setIsFirst(false);
  }, [isFirst]);

  useEffect(() => {
    if (subHeaderDropdown) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [subHeaderDropdown]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target)
      ) {
        setSubHeaderDropdown(false);
      }
    }
    document.addEventListener(
      "mousedown",
      handleClickOutside
    );
    return () => {
      document.removeEventListener(
        "mousedown",
        handleClickOutside
      );
    };
  }, []);

  return (
    <WidgetContainer stylesClass="blog-detail-subheader-widget-container">
      <div className="blog-detail-subheader-container">
        <a href="/" className="blog-back-link">
          <img src={Arrow} />
          All Tech Blogs
        </a>
        <div
          className="subheader-dropdown"
          ref={dropdownRef}
          onClick={handleSubheaderDropdown}
        >
          <span>
            {activeCategoryName}
            <img
              src={Arrow}
              className={classNames({
                "blog-detail-car-arrow-rotate-clock":
                  !isFirst && subHeaderDropdown,
                "blog-detail-car-arrow-rotate-ant-clock":
                  !isFirst && !subHeaderDropdown,
              })}
            />
          </span>
          <ul
            className={`blog-detail-cat-dropdown-container hide-scroller ${classNames(
              {
                "blog-subheader-active": subHeaderDropdown,
              }
            )}`}
          >
            <>
              {categoryMap.map(item => {
                return (
                  <li
                    key={item.id}
                    className={classNames({
                      "active-option":
                        getCategoryFromUrlPathName(
                          location.pathname
                        ) === item.slug,
                    })}
                  >
                    <Link
                      to={`/category/${item.slug}/`}
                      onClick={() =>
                        sendClickEvent(
                          TECH_BLOG_CATEGORY_DROP_DOWN,
                          `/category/${item.slug}/`
                        )
                      }
                    >
                      {item.name}
                    </Link>
                  </li>
                );
              })}
            </>
          </ul>
        </div>
      </div>
    </WidgetContainer>
  );
};

BlogDetailPageSubHeader.propTypes = {
  activeCategoryName: PropTypes.string,
  categoryMap: PropTypes.array,
  location: PropTypes.any,
};

export default BlogDetailPageSubHeader;
